var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-5 mb-10",attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticStyle:{"background":"#f5f5f5"}},[_c('v-card-title',{staticClass:"pt-10 pl-10"},[_vm._v("Sales Report by Product "),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.print}},[_c('v-icon',{staticClass:"pr-5",attrs:{"large":"","color":"black"}},[_vm._v("mdi-printer ")])],1)],1),_c('v-card',{staticStyle:{"background":"#f5f5f5"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-tabs',{attrs:{"background-color":"transparent","color":"primary","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1),_c('v-col',{staticClass:"d-flex flex-row",attrs:{"cols":"8"}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"padding":"0px !important"},attrs:{"label":"From Date","prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"padding":"0px !important"},attrs:{"label":"To Date","prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}})],1)],1),_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":_vm.seereport}},[_vm._v(" Full Report ")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',[_c('v-toolbar',{staticStyle:{"background":"#f5f5f5"},attrs:{"elevation":"2"}},[_c('v-toolbar-title',{staticClass:"mt-2 mb-2 ml-2",staticStyle:{"margin-left":"-14px"}},[_vm._v(" Daily Sales Report ")]),_c('v-spacer'),_c('div',{staticClass:"pr-5 pb-5 pt-5"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('div',{attrs:{"id":"dataapp"}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"background":"#f5f5f5","font-size":"10em"},attrs:{"headers":_vm.headers,"items":_vm.Sales,"search":_vm.search,"sort-by":"id","item-key":"id","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.spacification",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.product_color)+" , "+_vm._s(item.product_size))]}},{key:"item.warehouse",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.warehouse_name)+" , "+_vm._s(item.shop_name))]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"h4"},[_vm._v("Totals")]),_c('th',{staticClass:"h6"},[_vm._v(_vm._s(_vm.sumField("")))]),_c('th',{staticClass:"h6"},[_vm._v(_vm._s(_vm.sumField("")))]),_c('th',{staticClass:"h6"},[_vm._v(_vm._s(_vm.sumField("")))]),_c('th',{staticClass:"h6"},[_vm._v(_vm._s(_vm.sumField("")))]),_c('th',{staticClass:"h6"},[_vm._v(_vm._s(_vm.sumField("total_quantity")))]),_c('th',{staticClass:"h6"},[_vm._v(_vm._s(_vm.sumField("purchase_price")))]),_c('th',{staticClass:"h6"},[_vm._v(_vm._s(_vm.sumField("total_price")))]),_c('th',{staticClass:"h6"},[_vm._v(_vm._s(_vm.sumField("vat")))]),_c('th',{staticClass:"h6"},[_vm._v(_vm._s(_vm.sumField("discount")))]),_c('th',{staticClass:"h6"},[_vm._v(_vm._s(_vm.sumField("net_sale")))]),_c('th',{staticClass:"h6"},[_vm._v(_vm._s(_vm.sumField("profit")))]),_c('th',{staticClass:"h6"},[_vm._v(_vm._s(_vm.sumField("loss")))])])])],2)],1)],1),_c('v-tab-item',[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"background":"#f5f5f5","font-size":"10em"},attrs:{"headers":_vm.headersweek,"items":_vm.Salesweek,"search":_vm.searchweek,"sort-by":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticStyle:{"background":"#f5f5f5"},attrs:{"elevation":"2"}},[_c('v-toolbar-title',{staticClass:"mt-2 mb-2 ml-2",staticStyle:{"margin-left":"-14px"}},[_vm._v(" Weekly Sales Report ")]),_c('v-spacer'),_c('div',{staticClass:"pr-5 pb-5 pt-5"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.searchweek),callback:function ($$v) {_vm.searchweek=$$v},expression:"searchweek"}})],1)],1)]},proxy:true}])})],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"outlined":"","centered":"","color":"black"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }